<template>
  <component
    :is="tag"
    :id="id"
    class="media-library-asset my-2"
    draggable="true"
    :data-item-selected="isSelected"
    @dragstart="onDragStart($event)"
    @dragend="onDragEnd"
    @click="shiftCheck"
    :key="`asset-${id}`"
  >
    <img
      data-fallback="0"
      class="!min-h-[166px]"
      :src="validSrc"
      :alt="alt"
      loading="lazy"
      @error="onError($event)"
    />
    <div v-if="type === 'primary' && isMediaAsset">
      <i
        v-if="info?.is_global && folderId === 'all'"
        v-tooltip="'This media is globally shared and accessible throughout all your workspaces.'"
        class="far fa-crown text-google global-media-icon drop-shadow font-bold"
      ></i>
      <i
        v-if="isVideo(info['mime_type'])"
        v-b-tooltip.hover
        title="View"
        class="fas fa-video video-box"
        @click.prevent="viewItem"
      ></i>
      <i
          v-else-if="isPdf(info['mime_type'])"
          v-b-tooltip.hover
          class="fas fa-file-pdf image-box"
          title="View PDF in new tab"
          @click.prevent="viewPdf"
      >
      </i>
      <i
        v-else
        v-b-tooltip.hover
        title="View"
        class="fas fa-image image-box"
        @click.prevent="viewItem"
      ></i>
    </div>
    <i
      v-else
      v-b-tooltip.hover
      title="View"
      class="fas fa-eye image-box"
      @click.prevent="viewTabItem"
    ></i>
    <div
      class="media-library-asset__actions"
      :style="type !== 'primary' ? 'justify-content: flex-end' : ''"
    >
      <i
        v-if="type === 'primary'"
        title="Unselect"
        class="far fa-check-square"
        @click.prevent="checkBox"
      ></i>
      <i
        v-if="type === 'primary'"
        title="Select"
        class="far fa-square"
        @click.prevent="checkBox"
      ></i>

      <span>
        <i
          v-if="showRestore"
          title="Restore"
          class="far fa-recycle"
          @click.prevent="restoreItem"
        ></i>
        <i
          v-if="!hideDelete"
          title="Remove"
          class="far fa-trash-alt"
          @click.prevent="deleteItem"
        ></i>
        <i
          v-if="
            type === 'primary' &&
            showShare &&
            $route.query.type !== 'archived'
          "
          title="Compose Post"
          class="far fa-share-alt"
          @click.prevent="shareItem"
        ></i>
      </span>
    </div>

    <div class="media-library-asset__view">
      <!-- <i v-b-tooltip.hover title="View" v-if="type === 'primary' && !hideEye" class="fas fa-eye" @click.prevent="viewItem"></i> -->

      <div class="media-library-asset__file-info">
        <!-- <p   :title="info.name ? info.name : '' ">{{ info.name ? truncateString(info.name, 20) : '' }}</p> -->
        <p
          >{{ info.size ? bytesToSize(info.size) : '' }}
          {{
            info.duration_seconds
              ? '&nbsp; ♦ &nbsp;' + convertDuration(info.duration_seconds)
              : ''
          }}</p
        >
        <p>{{ updatedAt }}</p>
      </div>
      <i
          v-if="info?.is_global && folderId === 'all'"
          v-tooltip="'This media is globally shared and accessible throughout all your workspaces.'"
          class="far fa-crown text-google global-media-icon drop-shadow font-bold"
        ></i>
    </div>
  </component>
</template>

<script>
import useDateFormat from "@common/composables/useDateFormat";
import moment from 'moment'
import 'moment-duration-format'
import {
  getAssetId,
  DragDropMixin,
} from '@src/modules/publish/components/media-library/utils/helper.js'
import isEmpty from 'is-empty'
import { EventBus } from '@common/lib/event-bus'
import {useComposerHelper} from "@modules/composer_v2/composables/useComposerHelper";
export default {
  name: 'Asset',
  mixins: [DragDropMixin],
  props: {
    id: {
      default: null,
      type: [String, Number],
    },
    tag: {
      default: 'div',
      type: String,
    },
    src: {
      default: '',
      type: String,
    },
    alt: {
      default: '',
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    isSelected: {
      default: false,
      type: Boolean,
    },
    type: {
      default() {
        return 'primary'
      },
      type: String,
    },
    hideEye: {
      default() {
        return false
      },
      type: Boolean,
    },
    hideDelete: {
      default() {
        return false
      },
      type: Boolean,
    },
    showRestore: {
      default() {
        return false
      },
      type: Boolean,
    },
    info: {
      default() {
        return {
          name: 'Conference room.jpeg',
          size: '2.24 MB',
          updated: '',
          thumbnails: {},
        }
      },
      type: Object,
    },
    isMediaAsset: {
      default() {
        return false
      },
      type: Boolean,
    },
    showShare: {
      default() {
        return false
      },
      type: Boolean,
    },
    folderId: {
      default: 'all',
      type: String,
    },
  },
emits: ['check', 'delete', 'restore', 'view', 'share'],
  setup() {
    const { momentWrapper } = useDateFormat()
    const {openDraftComposer, draftPlanId} = useComposerHelper()
    return { openDraftComposer, draftPlanId, momentWrapper }
  },
  data() {
    return {
      index: getAssetId(),
    }
  },
  computed: {
    updatedAt() {
      return this.info.updated_at
        ? this.momentWrapper(
            this.info.created_at
          ).formatTimezone().formatDateTime()
        : ''
    },
    validSrc() {
      if (!isEmpty(this.info.thumbnails)) {
        return this.info.thumbnails.small
      }
      return this.src
    },
  },
  methods: {
    convertDuration(seconds) {
      const duration = moment.duration(seconds, 'seconds')
      return duration.format('mm [min] ss [s]')
    },
    onError(event) {
      const fallbackIndex = event.target.dataset.fallback
      let thumbnails = []
      if (!isEmpty(this.info?.thumbnails)) {
        thumbnails = Object.values(this.info.thumbnails)
      }
      // for heif images
      if (this.info.type && this.info.type === 'image/heif') {
        event.target.src =
          'https://storage.googleapis.com/lumotive-web-storage/placeholder.png'
        return
      }

      const fallbacks = [
        ...thumbnails,
        this.src,
        'https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png',
      ]

      if (fallbackIndex > fallbacks.length - 1) {
        event.target.src =
          'https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png'
        return
      }
      event.target.src = fallbacks[fallbackIndex]
      event.target.dataset.fallback++
    },

    shiftCheck(e) {
      if (e.shiftKey && this.type === 'primary') {
        this.$emit('check', this.$el)
      }
    },
    checkBox(e) {
      if (!e.shiftKey) {
        this.$emit('check', this.$el)
      }
    },
    deleteItem() {
      this.$emit('delete', this.id, this.info.link)
    },
    restoreItem() {
      this.$emit('restore', this.id, this.info.link)
    },
    viewItem() {
      this.$emit('view', this.info)
    },
    // open pdf in new tab
    viewPdf() {
      // open in new tab
      window.open(this.src, '_blank')
    },
    viewTabItem() {
      EventBus.$emit('open-tab-media-preview', this.src)
    },
    isVideo(mime) {
      if (mime) {
        return mime.includes('video/')
      } else {
        return false
      }
    },
    isPdf(mime) {
      if (mime) {
        return mime.includes('application/pdf')
      } else {
        return false
      }
    },
    async shareItem() {
      this.$emit('share', this.info)
    }
  },
}
</script>

<style lang="scss" scoped>
.video-box,
.image-box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #0707074b;
  padding: 10px;
  border-radius: 12px;
  z-index: 999;
  cursor: pointer;
  color: white;
  font-size: 20px;
  &:hover {
    background: #0707078f;
  }
}
.global-media-icon {
    position: absolute;
    bottom: 20px;
    right: 10px;
}
</style>
