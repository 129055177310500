import {
  allowedImageMimeTypes,
  allowedVideoMimeTypes,
} from '@src/modules/publish/config/api-utils'
import twitter from 'twitter-text'
import linkifyHtml from 'linkify-html'
import 'linkify-plugin-mention'
import 'linkify-plugin-hashtag'
import clip from 'text-clipper'
import moment from 'moment'

function intToString(value) {
  // console.debug(value)
  // Nine Zeroes for Billions
  return Math.abs(Number(value)) >= 1.0e9
    ? Number((Math.abs(Number(value)) / 1.0e9).toFixed(1)) + 'B'
    : // Six Zeroes for Millions
    Math.abs(Number(value)) >= 1.0e6
    ? Number((Math.abs(Number(value)) / 1.0e6).toFixed(1)) + 'M'
    : // Three Zeroes for Thousands
    Math.abs(Number(value)) >= 1.0e3
    ? Number((Math.abs(Number(value)) / 1.0e3).toFixed(1)) + 'K'
    : Number(value) === value && value % 1 !== 0
    ? Math.abs(Number(value)).toFixed(2)
    : Math.abs(Number(value))
}

function generateRandomId() {
  let text = ''
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < 30; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }
  return text
}

function getHashtagsFromText(searchText) {
  // eslint-disable-next-line no-useless-escape
  const regexp = /\B\#\w\w+\b/g
  return searchText.match(regexp)
}

function checkMaskingValidation(value) {
  if (value) {
    const res = value.split(':')
    if (res[0] && res[1]) {
      if (res[0] >= 0 && res[0] <= 23 && res[1] >= 0 && res[1] <= 59) {
        return true
      }
    }
  }
  return false
}

function isValidURL(url) {
  const regexp = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,12}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  return regexp.test(url)
}

function removeHtmlTags(text) {
  // for decoding html entities
  const txt = document.createElement('textarea')
  txt.innerHTML = text
  text = txt.value

  text = text.replace(/(<([^>]+)>)/gi, '')
  text = text.trim()

  return text
}

function getLinksFromText(text) {
  // eslint-disable-next-line no-useless-escape
  return text.match(
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g
  )
}

function getLinksFromTextComposer(text) {
  // eslint-disable-next-line no-useless-escape
  return text.match(
    /((ftp|http|https):\/\/)?[-a-zA-Z0-9@:%._/\+~#=]{2,256}\.[a-z]{2,15}\b([-a-zA-Z0-9@:%_\+.,~#?&//=]*)/g /* eslint-disable-line */
  )
  // return text.match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g)
}

function isStorageLink(url) {
  return (
    url.match(/(storage.googleapis\.com\/lumotive-web)/g) ||
    url.match(/(storage.googleapis\.com\/contentstudio-)/g)
  )
}

function parseDescriptionHtml(
  text,
  limit = 320,
  htmlEscape = true,
  isLinkedInInbox = false
) {
  try {
    let temp = htmlEscape ? twitter.htmlEscape(text) : text

    // Converting the template to html
    temp = temp.replace(
      /{\((.*?)\)\[(.*?)\]}/g,
      `<a href="$1" target="_blank" data-type="mention" class="pointer-events-none">$2</a>`
    )

    if (isLinkedInInbox) {
      temp = temp.replace(
        /@\[(.*?)\]\((.*?)\)/g,
        `<a href="$1" target="_blank" data-type="mention" class="pointer-events-none">$1</a>`
      )
      temp = temp.replace(/{hashtag\|\\#\|(.*?)}/g, `#$1`)
    }

    temp = linkifyHtml(temp, {
      target: '_blank',
      className: {
        mention: 'pointer-events-none',
        hashtag: 'pointer-events-none',
        url: 'hover:underline',
      },
    })

    // Fastest way to truncate a html string (https://github.com/arendjr/text-clipper)
    const clippedHtml = clip(temp, limit, { html: true })

    return clippedHtml
  } catch (e) {
    console.error('Error in parseDescriptionHtml', e)
    return text
  }
}

function parseDescription(description, limit) {
  console.debug('parseDescription :D ', description)
  if (description) {
    // Remove html tags from description
    // description = removeHtmlTags(description)
    return parseDescriptionHtml(description, limit)
    // if (description.length > limit) {
    //   let cloneDescription = encode(description.substring(0, limit))
    //   // let urls = getLinksFromText(cloneDescription)
    //   const urls = getLinksFromTextComposer(cloneDescription)
    //   console.debug('parseDescription urls are ', urls)
    //   for (const prop in urls) {
    //     if (!isStorageLink(urls[prop])) {
    //       cloneDescription = cloneDescription.replace(
    //         urls[prop],
    //         "<a href='" +
    //           urls[prop] +
    //           "' target='_blank' class='post_link'>" +
    //           urls[prop] +
    //           '</a>'
    //       )
    //     }
    //   }
    //
    //   return cloneDescription + '...'
  }
  // description = encode(description) commented this line because due to encoding HTML entities are not showing correctly in the planner.

  //   const urls = getLinksFromTextComposer(description)
  //   console.debug('parseDescription urls', urls)
  //   for (const prop in urls) {
  //     description = description.replace(
  //       urls[prop],
  //       "<a href='" +
  //         urls[prop] +
  //         "' target='_blank' class='post_link'>" +
  //         urls[prop] +
  //         '</a>'
  //     )
  //   }
  // }

  return description
}

function decodeTextURI(text) {
  try {
    return decodeURI(text)
  } catch (e) {
    return text
  }
}

/**
 * This method is used to check the mimes types of all the files provided by the user.
 * @param event
 * @param handlePdf - boolean to enable/disable pdf handling
 * @returns {{images: number, gifs: number, videos: number, others: number}}
 */
function parseFilesMimeTypes(event, handlePdf= false) {
  console.debug('Method::parseFilesMimeTypes', event)

  let files = []
  if (event.type === 'paste') {
    const itemImages = []
    const items = (event.clipboardData || event.originalEvent.clipboardData).items
    for (let i = 0; i <= items.length - 1; i++) {
      const item = items[i].getAsFile()
      if (item) {
        itemImages.push(item)
      }
    }
    files = itemImages
  } else {
    files = event.target.files || event.dataTransfer.files
  }

  const types = {
    images: 0,
    gifs: 0,
    videos: 0,
    others: 0,
    pdf: 0,
  }

  // iterating the files to check mime types
  for (let i = 0; i < files.length; i++) {
    console.debug('Method::parseFilesMimeTypes ~ file -> ', files[i])
    console.debug('Method::parseFilesMimeTypes ~ file type -> ', files[i].type)
    // incrementing the gif count if file type is gif
    if (files[i].type === 'image/gif') {
      console.debug('Method::parseFilesMimeTypes ~ gifs')
      types.gifs = types.gifs + 1
      continue
    }

    // incrementing the image count if file mime type is in the list of allowed image mimes types
    if (allowedImageMimeTypes.includes(files[i].type)) {
      console.debug('Method::parseFilesMimeTypes ~ images')
      types.images = types.images + 1
      continue
    }

    // incrementing the video count if file mime type is in the list of allowed video mimes types
    if (allowedVideoMimeTypes.includes(files[i].type)) {
      console.debug('Method::parseFilesMimeTypes ~ videos')
      types.videos = types.videos + 1
      continue
    }

    // incrementing the pdf count if file mime type is in the list of allowed pdf mimes types
    if (handlePdf && files[i].type === 'application/pdf') {
        console.debug('Method::parseFilesMimeTypes ~ pdf')
        types.pdf = types.pdf + 1
        continue
    }

    // incrementing the others count if file is not gif, image or video
    types.others = types.others + 1
  }
  console.debug('Method::parseFilesMimeTypes ~ returning.')
  return types
}

/**
 * This method is used memoize the vue methods passed to it and return the memoized return value.
 * Note: This should be used only for pure functions since it will not recompute if you pass a function with side effects.
 * @param fn - function to be memoized
 * @param debug - boolean to enable/disable debug logs in development mode
 * @param debugName - name of the function to be used in debug logs
 * @returns {function(...[*]=): *}
 */
function memoizeCallback(fn, debug = false, debugName = ' ') {
  const cache = new Map()

  // Auto clear cache if size exceeds 300
  // The proper way to auto clear cache is to check the memory usage and clear the cache if memory usage exceeds a certain limit
  // But since we are not using any external library (e.g object-sizeof) to check the memory usage, we are using the cache size to
  // clear the cache
  if (cache.size > 300) {
    cache.clear()
  }

  const memorized = function (...args) {
    const key = JSON.stringify(args)
    if (cache.has(key)) {
      if (debug) {
        console.log(
          '📓 Method::memoizeCallback ~ returning from cache.',
          debugName,
          cache.size
        )
      }
      return cache.get(key)
    } else {
      if (debug) {
        console.log(
          '📓 Method::memoizeCallback ~ returning new.',
          debugName,
          cache.size
        )
      }
    }
    const result = fn(...args)
    cache.set(key, result)
    return result
  }

  // cleanup function to clear cache
  memorized.prototype.clear = () => {
    if (debug) {
      console.log('📓 Method::memoizeCallback ~ clearing cashe.', debugName)
    }
    cache.clear()
  }

  return memorized
}

/**
 * extract hour(1-12) ,min(0-60),day and am pm
 * @param date
 * @returns {{hours: number, ampm: (string), minutes: (string|number), day: string}}
 */
function formatAMPM(date) {
  date = moment(date)
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]
  let hours = date.hours()
  let minutes = date.minutes()
  const day = days[date.day()]
  const ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours || 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes

  return {
    hours,
    minutes,
    ampm,
    day,
  }
}

/**
 * get 24hr format
 * @param amPmString
 * @returns {{hours: number}}
 */
function getTwentyFourHourTime(amPmString) {
  const d = moment('1/1/2013 ' + amPmString)
  return { hours: d.hours() }
}

//
/** get utc time by providing day in word, hour, minutes and am/pm
 *
 * @param day
 * @param hour
 * @param minutes
 * @param period
 * @returns {string}
 */
function getUtcDateFromDayAndTime(day, hour, minutes, period) {
  const daysInNumber = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  }
  const d = new Date()
  d.setDate(d.getDate() - d.getDay() + daysInNumber[day])
  const { hours } = getTwentyFourHourTime(`${hour}:${minutes} ${period}`)
  const dateCreated = new Date(
    `${d.getUTCFullYear()}-${
      d.getMonth() + 1
    }-${d.getDate()} ${hours}:${minutes}:00`
  )
  return dateCreated.toISOString()
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

function formatNotificationDate(date = '') {
  if(!date) return
  date = moment(date)
  if (date.isSame(moment(), 'day')) return 'Today'
  if (date.isSame(moment().subtract(1, 'days').startOf('day'), 'day'))
    return 'Yesterday'
  return date.format('dddd DD MMMM YY')
}

function convertToWorkspaceTimeZone(dateString, TIMEZONE) {
  const DATE_FORMAT = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }

  return new Date(
    moment.utc(dateString, 'YYYY-MM-DD HH:mm:ss').tz(TIMEZONE).format('YYYY-MM-DDTHH:mm:ss')
  ).toLocaleString('en-US', DATE_FORMAT);
}


export {
  memoizeCallback,
  parseDescriptionHtml,
  intToString,
  generateRandomId,
  getHashtagsFromText,
  checkMaskingValidation,
  isValidURL,
  removeHtmlTags,
  getLinksFromText,
  parseDescription,
  decodeTextURI,
  parseFilesMimeTypes,
  isStorageLink,
  getLinksFromTextComposer,
  formatAMPM,
  getUtcDateFromDayAndTime,
  getTwentyFourHourTime,
  capitalizeFirstLetter,
  formatNotificationDate,
  convertToWorkspaceTimeZone
}
