<template>
  <FacebookReelPreview
    v-if="
      facebookPostType === 'reel' &&
      (isComposerModalOpen || (isComposerModalOpen && account.type === 'Page'))
    "
    :class="{ 'max-w-[350px] mx-auto': type === 'planner' }"
    :account-name="account.name"
    :account-image="account.image"
    :video="detail.video"
    :description="getDescription"
    :toggle-preview="togglePreview"
  />
  <FacebookStoryPreview
    v-else-if="
      facebookPostType === 'story' &&
      (isComposerModalOpen || (!isComposerModalOpen && account.type === 'Page'))
    "
    :class="{ 'max-w-[350px] mx-auto': type === 'planner' }"
    :story-image="detail?.image ? detail.image[0] : ''"
    :account-name="account?.name"
    :account-image="account?.image"
    :video="detail?.video"
    :description="getDescription"
    :toggle-preview="togglePreview"
    :thumbnail="detail?.video?.thumbnail"
  />
  <div v-else class="w-full cst-drop-shadow bg-white rounded-xl">
    <div class="flex items-center px-3 py-3">
      <img
        v-if="isCarouselPost"
        class="carousel rounded-full object-cover border border-solid border-black"
        width="40"
        height="40"
        :src="carouselAccount.image"
        alt=""
        @error="
          $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
        "
      />
      <template v-else>
        <img
          v-if="publishAs && publishAs.image"
          class="publish rounded-full object-cover border border-solid border-black"
          width="40"
          height="40"
          :src="publishAs.image"
          alt=""
          @error="
            $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
          "
        />
        <img
          v-else
          class="aacount rounded-full object-cover border border-solid border-black"
          width="40"
          height="40"
          :src="getProfileImage"
          alt=""
          @error="
            $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
          "
        />
      </template>

      <div class="px-2 font-bold">
        <span v-if="isCarouselPost">{{ carouselAccount.name }}</span>
        <span v-else>
          <span v-if="publishAs && publishAs.name" class=""
            >{{ publishAs.name }}<i class="fa fa-caret-right mx-1"></i
          ></span>
          {{ account.name }}
        </span>
        <span v-if="isLocationAdded">
          <span class="font-normal"> is in</span>
          {{ isLocationAdded }}
        </span>
        <span class="block font-normal text-xs text-gray-800">
          <span v-if="executionTime.date">{{
              getWorkspaceTimeZoneTime(
                  executionTime.date,
                  getAccountDateTimeFormat
              )
            }}</span>
          <span v-else>{{ getWorkspaceTimezonCurrentTime(getAccountDateTimeFormat) }}</span>
        </span>
      </div>
      <div
        class="ml-auto inline-flex justify-center items-center w-8 h-8 rounded-lg bg-cs-light-blue"
      >
        <img
          src="@assets/img/integration/facebook-icon.svg"
          alt=""
          class="w-5"
        />
      </div>
    </div>
    <div v-if="shouldRenderFacebookBackgroundPreview" class="w-full h-full">
      <!-- Facebook Template Preview -->
      <FacebookBackgroundPreview
          :facebook-background-id="facebookBackgroundId"
          :description="getDescription"
      />
      <!-- Facebook Template Preview End -->
    </div>
    <div v-else-if="getDescription" class="items-center px-3 py-1 pb-3">
      <span
        class="whitespace-pre-wrap leading-4"
        v-html="getDescription"
      ></span>
      <span
        v-if="getDescription.length > charLimit && loadMore"
        class="text-blue-300 cursor-pointer"
        @click="changeLoadMore(false)"
      >
        See More</span
      >
      <span
        v-if="getDescription.length > charLimit && !loadMore"
        class="text-blue-300 cursor-pointer"
        @click="changeLoadMore(true)"
      >
        Show Less</span
      >
    </div>
    <div :class="{'mb-2': !facebookBackgroundId}" class="w-full h-full">
      <div v-if="isCarouselPost">
        <Carousel
          id="preview-carousel"
          class="facebook-carousel-preview"
          :navigation-next-label="navigationNext"
          :navigation-prev-label="navigationPrev"
          :navigation-enabled="true"
          :pagination-enabled="false"
          :scroll-per-page="false"
          :space-padding-max-offset-factor="20"
          :per-page="2"
        >
          <template v-for="(card, index) in carousel.cards" :key="`carousel-index-${index}`">
            <Slide class="w-[332px]">
              <div class="block w-full bg-white border rounded-md mr-2">
                <div class="shadow-sm w-full border">
                  <img
                    v-tooltip="{
                      content: 'Carousel Preview',
                    }"
                    class="w-full object-cover cursor-pointer"
                    :class="getSlideClasses"
                    :src="card.picture"
                    alt=""
                    @click="() => openImageLightBox(carousel.cards, index)"
                    @error="
                      (event) => {
                        event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                      }
                    "
                  />
                </div>
                <a
                  :href="card.url ? card.url : 'javascript:;'"
                  :target="card.url ? '_blank' : '_self'"
                >
                  <div
                    class="footer w-full h-20 max-h-20 py-2 px-2 overflow-hidden cursor-pointer bg-[#e4e6eb33]"
                    style="min-height: 5rem"
                  >
                    <div class="flex">
                      <div class="w-full">
                        <div class="title-default">
                          {{ card.name }}
                        </div>
                        <div class="description-default">
                          {{ card.description }}
                        </div>
                      </div>
                      <div
                        v-if="carousel.call_to_action !== 'NO_BUTTON'"
                        class="w-max flex items-center justify-center"
                      >
                        <a
                          class="border cursor-pointer font-medium w-max p-2 rounded-md text-[#4b4f56] bg-[#e4e6eb]"
                          :href="card.url ? card.url : 'javascript:;'"
                          :target="card.url ? '_blank' : '_self'"
                        >
                          {{
                            FacebookCallToAction.find(
                              (action) =>
                                action.type === carousel.call_to_action,
                            ).name
                          }}
                        </a>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </Slide>
          </template>
          <Slide
            v-if="carousel.end_card && carousel.end_card_url"
            class="w-[332px]"
          >
            <div class="block w-full bg-white border rounded-md mr-2">
              <div class="shadow-sm w-full border">
                <img
                  class="w-full object-cover p-[20%]"
                  :class="getSlideClasses"
                  :src="
                    carouselAccount && carouselAccount.image
                      ? carouselAccount.image
                      : 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
                  "
                  alt=""
                  @error="
                    (event) => {
                      event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                    }
                  "
                />
              </div>
              <a
                :href="
                  carousel.end_card_url ? carousel.end_card_url : 'javascript:;'
                "
                :target="carousel.end_card_url ? '_blank' : '_self'"
              >
                <div
                  class="footer w-full h-20 max-h-20 py-3 px-2 overflow-hidden cursor-pointer text-center"
                  style="background-color: #e4e6eb33"
                >
                  <div class="w-full font-bold text-gray-900">See more at</div>
                  <div
                    class="w-full overflow-hidden font-normal text-gray-800 uppercase"
                    >{{ getHostName }}
                  </div>
                </div>
              </a>
            </div>
          </Slide>
        </Carousel>
      </div>
      <div v-else-if="detail.url && detail.image.length > 0" class="border">
        <div
          class="bg-cover bg-no-repeat bg-center min-h-[160px] max-h-[500px]"
          :style="{ 'background-image': 'url(' + detail.image[0] + ')' }"
        >
          <img
            class="max-w-full max-h-full opacity-0 w-full"
            :src="detail.image[0]"
            alt=""
            @error="
              (event) => {
                event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                event.target.classList.remove('opacity-0')
              }
            "
          />
        </div>
        <div
          v-if="detail.website || detail.title"
          class="bg-[#F0F2F5] w-full h-max py-2 px-3 relative"
        >
          <div
            class="absolute right-5 -top-3 z-50 bg-white rounded-full py-2 px-3"
            ><i class="fas fa-info rotate-6"></i
          ></div>
          <div
            v-if="detail.website"
            class="block uppercase text-gray-800 text-sm"
            >{{ getHostOnly(detail.website) }}</div
          >
          <div
            v-if="detail.title"
            class="font-semibold text-[0.9rem] leading-4 truncate"
            >{{ detail.title }}</div
          >
          <div
            v-if="detail.description"
            class="truncate text-sm leading-4 mt-0.5 text-gray-800"
            >{{ detail.description }}</div
          >
        </div>
      </div>
      <div
        v-else-if="detail.image && detail.image.length > 0"
        class="flex flex-wrap relative overflow-hidden"
      >
        <template v-for="(image, index) in detail.image" :key="`image-index-${index}`">
          <div
            v-if="index <= 3"
            class="flex-img"
            :class="[
              detail.image.length === 3 && 'img-three',
              !$window.location.href.includes('/composer/')
                ? 'h-[26rem]'
                : 'h-[16rem]',
            ]"
          >
            <img
              v-tooltip="{
                content: 'Preview Image',
                placement: 'auto',
              }"
              class="max-w-full max-h-full object-contain w-full cursor-pointer"
              :style="'height: inherit;'"
              :class="[
                detail.image.length === 1 ? 'object-contain' : 'object-cover',
              ]"
              :src="image"
              alt=""
              @click="() => openImageLightBox(detail.image, index)"
              @error="
                (event) => {
                  event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                }
              "
            />
          </div>
          <div
            v-else
            class="absolute top-3/4 left-3/4 p-2 text-2xl text-bold text-white"
            style="transform: translate(-50%, -50%)"
            >+{{ detail.image.length - 4 }}
          </div>
        </template>
      </div>
      <div
        v-else-if="detail.video && detail.video.thumbnail"
        class="relative h-100 text-white text-center"
      >
        <img
          class="w-full min-h-48 max-h-[500px] object-contain"
          :src="detail.video.thumbnail"
          alt=""
        />

        <!-- playback icon -->
        <div
          class="absolute top-0 left-0 h-full w-full cursor-pointer z-10"
          @click.prevent="
            displayFile(
              'video',
              detail.video.converted_video || detail.video.link,
              0,
            )
          "
        >
          <i
            class="fas fa-play cursor-pointer absolute top-1/2 left-1/2 p-4 rounded-full bg-[#00000070] border-solid -translate-x-1/2 -translate-y-1/2"
          ></i>
        </div>
      </div>
    </div>

    <div
      v-if="commentDetails?.first_comment !== undefined || comment"
      class="flex items-center justify-center px-2 py-3 hr-color"
    >
      <div>
        <img
          class="rounded-full object-cover border border-solid border-black"
          width="30"
          height="30"
          :src="getProfileImage"
          alt=""
          @error="
            $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
          "
        />
      </div>
      <div class="ml-2 w-full bg-cs-slate rounded-lg py-2 px-2">
        {{ comment }}
      </div>
    </div>
    <div
      v-if="commentDetails?.first_comment !== undefined"
      class="comments-status-box"
    >
      <p v-if="commentDetails?.first_comment?.status === true" class="ml-2">
        <span class="status published"></span>
        <span class="text">Published</span>
        <span> | [First comment has been posted successfully.]</span>
      </p>
      <p v-else-if="!commentDetails?.first_comment?.status" class="ml-2">
        <span class="status failed"></span>
        <span class="text">Failed</span>
        <span> | {{ commentDetails?.first_comment?.message }}</span>
      </p>
    </div>
  </div>
  <VueEasyLightbox
    :visible="visible"
    :imgs="imgs"
    :index="selectedImageIndex"
    @hide="handleHide"
  ></VueEasyLightbox>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox'
import { FacebookCallToAction } from '@common/constants/common-attributes'
import { Carousel, Slide } from '@jambonn/vue-concise-carousel'
import { EventBus } from '@common/lib/event-bus'
import { parseDescriptionHtml } from '@common/lib/helper'
import FacebookReelPreview from '@src/modules/composer_v2/components/SocialPreviews/FacebookReelPreview'
import FacebookStoryPreview from '@src/modules/composer_v2/components/SocialPreviews/FacebookStoryPreview.vue'
import FacebookBackgroundPreview from '@modules/composer_v2/components/SocialPreviews/FacebookBackgroundPreview.vue'
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper'
import useDateFormat from "@common/composables/useDateFormat";

export default {
  name: 'FacebookPreview',
  components: {
    FacebookStoryPreview,
    Carousel,
    Slide,
    FacebookReelPreview,
    FacebookBackgroundPreview,
    VueEasyLightbox,
  },
  props: {
    commonBoxStatus: {
      type: Boolean,
      default: false,
      required: true,
    },
    account: {
      type: [Object, Array],
      default: () => ({}),
      required: true,
    },
    detail: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    publishAs: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    carousel: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    carouselAccount: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    comment: {
      type: String,
      default: '',
      required: false,
    },
    executionTime: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    facebookPostType: {
      type: String,
      default: 'feed',
    },
    togglePreview: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'composer',
    },
    commentDetails: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    facebookBackgroundId: {
      type: String,
      default: '',
    },
    facebookGroupSelected: {
      type: Boolean,
      default: false,
    },
    facebookPageSelected: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['set-facebook-post-type'],
  setup() {
    const { isComposerModalOpen } = useComposerHelper()
    const { momentWrapper,getAccountDateTimeFormat} = useDateFormat()
    return { isComposerModalOpen,momentWrapper,getAccountDateTimeFormat }
  },
  data() {
    return {
      FacebookCallToAction,
      loadMore: true,
      charLimit: 500,
      isFeedView: false,
      visible: false,
      imgs: [],
      selectedImageIndex: 0,
    }
  },
  computed: {
    getSlideClasses() {
      return [
        this.isComposerModalOpen
          ? 'h-[160px]'
          : this.isFeedView
          ? 'h-[100px] sm:h-[120px] md:h-[150px] lg:h-[170px] xl:h-[200px] 2xl:h-[232px]'
          : 'h-[200px] sm:h-[160px] md:h-[180px] lg:h-[200px] xl:h-[250px] 2xl:h-[360px]',
      ]
    },
    /**
     * Check if the Facebook Background Preview should be rendered
     * @returns {""|false|boolean}
     */
    shouldRenderFacebookBackgroundPreview() {
      return (
        this.facebookBackgroundId &&
        !this.carousel?.is_carousel_post &&
        !this.detail.url &&
        !this.detail.image.length &&
        !this.detail.video.thumbnail &&
        this.detail.message.length <=130 &&
        (!this.facebookGroupSelected || this.facebookPageSelected)
      )
    },
    navigationNext: function () {
      return `<i class="fas fa-chevron-right"></i>`
    },
    navigationPrev: function () {
      return `<i class="fas fa-chevron-left"></i>`
    },
    getHostName() {
      const urlParts = /^(?:\w+:\/\/)?([^/]+)([^?]*)\??(.*)$/.exec(
        this.carousel.end_card_url
      )
      return urlParts[1] ? urlParts[1] : ''
    },
    isLocationAdded() {
      if (this.commonBoxStatus) return this.detail.location?.facebook?.name
      return this.detail.location?.name
    },
    isCarouselPost() {
      return this.carouselAccount?.name && this.carousel?.is_carousel_post
    },
    getDescription() {
      let msg = this.detail.message || ''
      msg = parseDescriptionHtml(msg, 5000)
      if (msg.length > this.charLimit && this.loadMore) {
        msg = msg.substr(0, this.charLimit).concat('...')
        return msg
      }
      return msg
    },
    getProfileImage() {
      return (
        this.account?.image ||
        'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
      )
    },
    noMediaFound() {
      return !this.detail?.image?.length && !this.detail?.video?.link
    },
  },
  watch: {
    noMediaFound(val) {
      this.setFacebookPostType('feed')
    },
  },
  mounted() {
    this.isFeedView = this.$route.name.includes('feed')
  },
  beforeUnmount() {
    this.isFeedView = false
  },
  methods: {
    changeLoadMore(val) {
      this.loadMore = val
    },
    getHostOnly(url) {
      const urlParts = /^(?:\w+:\/\/)?([^/]+)([^?]*)\??(.*)$/.exec(url)
      return urlParts[1] ? urlParts[1] : ''
    },
    displayFile(type, media, index) {
      EventBus.$emit('displayFile', {
        type,
        media,
        index,
      })
      this.$bvModal.show('display-file-modal')
    },
    setFacebookPostType(value) {
      EventBus.$emit('set-facebook-post-type', value)
    },
    openImageLightBox(item, index = 0) {
      this.selectedImageIndex = index
      this.imgs = this.isCarouselPost
        ? item.map((card) => card.picture)
        : [...item]
      this.visible = true
    },
    handleHide() {
      this.visible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.last-slide {
  flex-basis: 100% !important;
  margin: 0 !important;
}
.flex-img {
  flex: 1 0 calc(47.33% - 10px);
  border: 1px solid white;
}

.img-three {
  flex: 1 1 calc(29.33% + 10px) !important;
  border: 1px solid white;
}

.title-default {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #343434;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.description-default {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #747474;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 100%;
  max-height: 25px;
  white-space: pre-wrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
</style>
