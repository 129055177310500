<template>
  <div
    style="max-height: calc(100% - 60px)"
    class="w-full bg-[#FBFCFC] text-black-900 flex flex-col h-full overflow-hidden"
  >
    <!-- chat case-->
    <div
      ref="conversationBox"
      class="flex-col w-full flex-1 p-4 justify-between overflow-auto scroll-smooth relative"
      style="overscroll-behavior: none"
    >
      <template v-if="props.activeChat.messages.length > 0">
        <template
          v-for="(message, index) in props.activeChat.messages"
          :key="index"
        >
          <UserChatTemplate
            v-if="message.role === 'user'"
            :message="message"
            :type="props.type"
            :save-prompt="props.handleCustomPrompts"
          />
          <BotChatTemplate
            v-else
            :message="message"
            :type="props.type"
            :delete-message="props.deleteMessage"
          />
        </template>
        <div v-if="props.messageLoader" class="flex justify-center w-full pb-2">
          <div
            class="py-2 px-3 inline-flex justify-center item-center rounded"
            style="box-shadow: 0 1px 8px 0 rgba(0, 27, 70, 0.06)"
          >
            <beatLoader :color="'#436aff'" size="8px" class="h-4"></beatLoader>
          </div>
        </div>
        <div ref="lastMessageDiv" class="opacity-0"></div>
      </template>
      <template v-else-if="props.activeChatLoader">
        <div class="absolute bottom-0 left-[48%]">
          <beatLoader size="8px" :color="'#436aff'"></beatLoader>
        </div>
      </template>
      <!-- no chat case -->
      <template v-else>
        <div
          class="mt-24 w-full text-center flex-1 flex flex-col items-center justify-center px-4"
        >
          <img
            src="@src/assets/img/chat_bot/cyrus-icon-blue-light.svg"
            class="w-[9.25rem]"
            alt=""
          />
          <div class="whitespace-nowrap font-medium text-lg mt-4">
            Hey {{ getUserName }}!
          </div>
          <div class="text-gray-700 font-normal text-base"
            >How may I help you today?</div
          >
        </div>
      </template>
    </div>
    <div
      id="quick-prompts"
      ref="quickPromptsRef"
      class="bg-[#FBFCFC] w-full"
      style="border-radius: 0 0 8px 8px"
    >
      <!-- quick prompts -->
       <!-- remove my-2 class from the div below when you add the favourite prompts section -->
      <div class="mx-2 px-2 border my-2">
        <div
          class="px-0.5 my-[0.5rem] w-full flex items-center justify-between cursor-pointer"
          @click="toggleQuickPrompts"
        >
          <span
            class="font-semibold text-base"
            :class="{ '!text-cs-primary': !quickPromptsOpen }"
          >
            Quick Prompts
          </span>
          <i
            class="fa fa-chevron-down cursor-pointer text-xs text-gray-700"
            :class="{ 'rotate-180 text-cs-primary': !quickPromptsOpen }"
          ></i>
        </div>
        <transition name="slide-bottom">
          <div v-if="quickPromptsOpen" class="mt-1 mb-2">
            <div class="flex flex-wrap gap-1.5">
              <template
                v-for="(prompt, index) in AiChatQuickPrompts"
                :key="index"
              >
                <div
                  v-tooltip="{ content: prompt }"
                  class="bg-[#F4F6FA] text-sm text-[#000D21] px-2 py-1 rounded w-max cursor-pointer hover:bg-[#ECEEF5]"
                  :class="{
                    'border border-blue-300': clickedPrompt === prompt,
                  }"
                  @click="handlePromptClick(prompt)"
                  >{{ prompt }}
                </div>
              </template>
            </div>
          </div>
        </transition>
      </div>

      <!-- Favourite Prompts -->

      <!-- Hide current favourite prompts section -->

      <!-- <div class="px-2 border m-2">
        <div
          class="px-0.5 my-[0.5rem] w-full flex items-center justify-between cursor-pointer"
          @click="toggleFavouritePrompts"
        >
          <span
            class="font-semibold text-base"
            :class="{ '!text-cs-primary': !favouritePromptsOpen }"
          >
            Favourite
          </span>
          <i
            class="fa fa-chevron-down cursor-pointer text-xs text-gray-700"
            :class="{ 'rotate-180 text-cs-primary': !favouritePromptsOpen }"
          ></i>
        </div>
        <transition name="slide-bottom">
          <div v-if="favouritePromptsOpen" class="mt-1 mb-2">
            <div class="flex flex-wrap gap-1.5">
              <template v-for="(prompt, index) in props.prompts" :key="index">
                <div
                  v-tooltip="{ content: prompt.prompt }"
                  class="text-sm text-[#000D21] px-2 py-1 rounded w-max cursor-pointer"
                  :style="{
                    border: `1px solid ${
                      clickedPrompt === prompt._id
                        ? '#007bff'
                        : prompt.color_code
                    }`,
                    backgroundColor: prompt.color_code || '#F4F6FA',
                  }"
                  @click="handlePromptClick(prompt, 'custom')"
                  >{{ prompt.title }}
                </div>
              </template>
            </div>
          </div>
        </transition>
      </div> -->

      <!-- input -->
      <div
        class="px-4 py-4 w-full min-h-[10rem] bg-[#F4F6FA] relative"
        style="border-top: 1px solid #eaf0f6"
      >
        <div
          class="bg-white p-4 rounded-lg"
          style="box-shadow: 0 4px 15px -5px rgba(25, 0, 125, 0.1)"
        >
          <textarea
            ref="inputRef"
            v-model="chatInput"
            class="chatInput resize-none w-full border-0 text-base h-full rounded-lg !max-h-[225px]"
            placeholder="Type your message here"
            @keydown="handleKeyEvents"
          >
          </textarea>
          <div
            class="mt-4 w-full flex items-center justify-between bg-white rounded-lg"
          >
            <p
              class="text-[#0068E5] text-base font-normal rounded px-4 py-1.5 cursor-pointer select-none"
              style="border: 1px solid #0068e5"
              @click="props.toggleChatDialogs('openSavedPrompts')"
            >
              Prompts
            </p>
            <p
              class="bg-cs-primary text-white text-base font-normal rounded px-4 py-1.5 cursor-pointer select-none flex items-center gap-x-2 hover:bg-opacity-75"
              :class="{
                'opacity-50 !cursor-not-allowed':
                  props.activeChatLoader || props.messageLoader,
              }"
              style="border: 1px solid #0068e5"
              @click="handleSendMessage"
            >
              Send
              <img
                src="@src/assets/img/chat_bot/send_icon.svg"
                alt="send_icon"
                width="14"
                height="14"
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// using recommended syntax  and eslint disabled until we update linting rules
/* eslint-disable */
import {
  computed,
  onMounted,
  reactive,
  ref,
  defineProps,
  onUpdated,
  watch,
  nextTick,
} from 'vue'
import { AiChatQuickPrompts } from '@src/modules/AI-tools/Prompts.js'
import { useStore } from '@state/base'
import BotChatTemplate from '@src/modules/AI-tools/BotChatTemplate.vue'
import UserChatTemplate from '@src/modules/AI-tools/UserChatTemplate.vue'
import { EventBus } from '@common/lib/event-bus'

const store = useStore()

const props = defineProps({
  type: {
    type: String,
    default: 'modal',
  },
  modalToggler: {
    type: Boolean,
    default: false,
  },
  activeChatLoader: {
    type: Boolean,
    default: false,
  },
  messageLoader: {
    type: Boolean,
    default: false,
  },
  activeChat: {
    type: Object,
    default: () => {},
  },
  fetchActiveChat: {
    type: Function,
    default: () => {},
  },
  sendMessage: {
    type: Function,
    default: () => {},
  },
  deleteMessage: {
    type: Function,
    default: () => {},
  },
  toggleChatDialogs: {
    type: Function,
    default: () => {},
  },
  promptInput: {
    type: String,
    default: '',
  },
  isCreditVisible: {
    type: Boolean,
    default: false,
  },
  handleCustomPrompts: {
    type: Function,
    default: () => {},
  },
  prompts: {
    type: Array,
    default: () => [],
  },
})
const quickPromptsRef = ref(null)
const chatInput = ref('')
const inputRef = ref(null)
const lastMessageDiv = ref(null)
const conversationBox = ref(null)
const getUserName = computed(() => {
  return store.getters.getProfile?.firstname
})
const handleKeyEvents = (event) => {
  if (props.activeChatLoader || props.messageLoader) {
    return
  }
  if (!event.shiftKey && event.key === 'Enter') {
    event.preventDefault()
    handleSendMessage()
  }
}
const handleSendMessage = () => {
  if (props.activeChatLoader || props.messageLoader) return
  if (chatInput.value.trim() !== '' && props.activeChatLoader === false) {
    props.sendMessage(chatInput.value)
    chatInput.value = ''
    scrollToLastMessage()
  }
}
const scrollToLastMessage = () => {
  const ele = conversationBox.value
  if (ele) {
    setTimeout(() => {
      ele.scrollTop = ele.scrollHeight
    }, 30)
  }
}
const scrollSmooth = () => {
  const ele = lastMessageDiv.value
  if (ele) {
    setTimeout(() => {
      ele.scrollIntoView({ behavior: 'smooth' })
    }, 500)
  } else {
    scrollToLastMessage()
  }
}

const appendQuickPrompt = (prompt) => {
  chatInput.value = prompt
  // Focus on the input after appending the prompt
  nextTick(() => {
    inputRef.value?.focus()
  })
}
const adjustInputHeight = async (event) => {
  const ele = inputRef.value
  // await next Dom update
  await nextTick()
  ele.style.height = 'auto'
  ele.style.height = `${ele.scrollHeight}px`
}

// New reactive references for collapsible sections
const quickPromptsOpen = ref(true)
const favouritePromptsOpen = ref(true)
const clickedPrompt = ref(null)

const handlePromptClick = (prompt, type = 'quick') => {
  clickedPrompt.value = prompt._id
  appendQuickPrompt(type === 'custom' ? prompt.prompt : prompt)

  // Reset the clickedPrompt after a short delay
  setTimeout(() => {
    clickedPrompt.value = null
  }, 500) // Adjust the delay as needed
}

// Toggle functions for collapsible sections
const toggleQuickPrompts = () => {
  quickPromptsOpen.value = !quickPromptsOpen.value
}

const toggleFavouritePrompts = () => {
  favouritePromptsOpen.value = !favouritePromptsOpen.value
}

watch(
  () => props.activeChat,
  (newVal, oldVal) => {
    scrollToLastMessage()
    // focus on input
    const ele = inputRef.value
    if (ele) {
      ele.focus()
    }
  },
  { deep: true }
)

watch(
  () => props.activeChatLoader,
  (value) => {
    scrollSmooth()
  }
)
watch(
  () => props.modalToggler,
  (value) => {
    if (value) {
      props.fetchActiveChat()
      scrollSmooth()
    }
  }
)

watch(
  () => store.getters.getWorkspaces.activeWorkspace._id,
  (newValue) => {
    if (newValue) props.fetchActiveChat()
  },
  { immediate: true }
)

onMounted(() => {
  EventBus.$on('append-prompt', (value) => {
    chatInput.value = value
    const ele = inputRef.value
    if (ele) {
      ele.setSelectionRange(ele.value.length, ele.value.length)
      inputRef.value.focus()
    }
  })
})
watch(
  () => chatInput.value,
  (value) => {
    adjustInputHeight()
  }
)
</script>

<style lang="scss">
.ai-chat-message {
  .chat-codeblock {
    color: #000000ad;
    font-weight: 300;
    width: 100%;
    border-radius: 5px;
    padding: 5px;
    // border: 3px solid #f5f5f5;
    display: block;
    // background: white;
    // font-size: 87.5%;
    word-wrap: break-word;
  }
  .chat-hashtag {
    color: #007bff;
  }
}
</style>
