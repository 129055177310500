<template>
  <div class="w-full flex items-center justify-center">
    <CstDropdown
      class="my-2"
      size="small"
      dropdown-placement="top"
      button-classes="flex justify-between bg-cs-light-gray"
    >
      <template v-slot:selected>
        {{ $filters.formatNumber(displayHour) }}
      </template>
      <template v-slot:arrow>
        <i class="far fa-angle-down"></i>
      </template>
      <CstDropdownItem
        v-for="h in hours"
        :key="h"
        :value="h"
        :class="
          displayHour === h
            ? 'bg-[#0068e5] text-white hover:bg-[#0068e5] hover:text-white active'
            : ''
        "
        @click="handleSelect(h, 'hours')"
        >{{ $filters.formatNumber(h) }}
      </CstDropdownItem>
    </CstDropdown>

    <span class="mx-1.5 font-bold">:</span>
    <CstDropdown
      class="my-2"
      size="small"
      dropdown-placement="top"
      button-classes="flex justify-between bg-cs-light-gray"
    >
      <template v-slot:selected>
        {{ $filters.formatNumber(minute) }}
      </template>
      <template v-slot:arrow>
        <i class="far fa-angle-down"></i>
      </template>
      <CstDropdownItem
        v-for="m in minutes"
        :key="m"
        :value="m"
        :class="
          minute === m
            ? 'bg-[#0068e5] text-white hover:bg-[#0068e5] hover:text-white active'
            : ''
        "
        @click="handleSelect(m, 'minutes')"
        >{{ $filters.formatNumber(m) }}
      </CstDropdownItem>
    </CstDropdown>

    <CstDropdown
      v-if="!hour24"
      class="my-2 ml-2"
      size="small"
      dropdown-placement="top"
      button-classes="flex justify-between bg-cs-light-gray"
    >
      <template v-slot:selected>
        {{ ampm }}
      </template>
      <template v-slot:arrow>
        <i class="far fa-angle-down"></i>
      </template>
      <CstDropdownItem
        v-for="period in ['AM', 'PM']"
        :key="period"
        :value="period"
        :class="
          ampm === period
            ? 'bg-[#0068e5] text-white hover:bg-[#0068e5] hover:text-white active'
            : ''
        "
        @click="handleSelect(period, 'ampm')"
        >{{ period }}
      </CstDropdownItem>
    </CstDropdown>
  </div>
</template>

<script>
import CstDropdown from '@ui/Dropdown/CstDropdown'
import CstDropdownItem from '@ui/Dropdown/CstDropdownItem'
import useDateFormat from '@common/composables/useDateFormat'

const { momentWrapper } = useDateFormat()

export default {
  name: 'SelectTime',
  components: {
    CstDropdown,
    CstDropdownItem,
  },
  props: {
    selectedDate: {
      type: Date,
      default: new Date(),
    },
    emit: {
      type: Function,
      default: () => {},
    },
    hour24: {
      type: Boolean,
      default: false,
    },
    minuteIncrement: {
      type: Number,
      default: 5,
    },
  },
  data() {
    const currentTime = momentWrapper(this.selectedDate) || momentWrapper()
    const hours = currentTime.hours()
    return {
      hour: hours,
      displayHour: this.hour24 ? hours : hours % 12 || 12,
      minute:
        currentTime.minutes() - (currentTime.minutes() % this.minuteIncrement),
      second: currentTime.seconds(),
      ampm: hours < 12 ? 'AM' : 'PM',
    }
  },
  computed: {
    hours() {
      const values = []
      const max = this.hour24 ? 24 : 12
      for (let i = 0; i < max; i++) {
        values.push(this.hour24 ? i : i + 1)
      }
      return values
    },
    minutes() {
      const values = []
      const max = 60
      for (let i = 0; i < max; i += this.minuteIncrement) {
        values.push(i)
      }
      return values
    },
  },
  methods: {
    checkInvalidTime(displayHour, minute, ampm) {
      const todaysDate = momentWrapper().formatTimezone()
      const selectedDate = momentWrapper(this.selectedDate)

      if (
        todaysDate.date() === selectedDate.date() &&
        todaysDate.month() === selectedDate.month() &&
        todaysDate.year() === selectedDate.year()
      ) {
        const currentHour = todaysDate.hour()
        const currentMinute = todaysDate.minutes()
        let hour = this.hour24
          ? displayHour
          : ampm === 'PM' && displayHour !== 12
          ? displayHour + 12
          : displayHour === 12 && ampm === 'AM'
          ? 0
          : displayHour

        if (
          currentHour > hour ||
          (currentHour === hour && minute < currentMinute)
        ) {
          return true // The selected time is in the past
        }
      }
      return false // The selected time is valid
    },
    handleSelect(value, type) {
      let newHour = this.displayHour
      let newMinute = this.minute
      let newAmpm = this.ampm

      if (type === 'hours') {
        newHour = value
      } else if (type === 'minutes') {
        newMinute = value
      } else if (type === 'ampm') {
        newAmpm = value
      }
      if (type === 'hours') {
        this.displayHour = newHour
        this.hour = this.hour24
          ? newHour
          : newAmpm === 'PM' && newHour !== 12
          ? newHour + 12
          : newHour === 12 && newAmpm === 'AM'
          ? 0
          : newHour
      } else if (type === 'minutes') {
        this.minute = newMinute
      } else if (type === 'ampm') {
        this.ampm = newAmpm
        if (!this.hour24) {
          if (newAmpm === 'PM' && this.hour < 12) {
            this.hour += 12
            this.displayHour = this.hour % 12 || 12
          } else if (newAmpm === 'AM' && this.hour >= 12) {
            this.hour -= 12
            this.displayHour = this.hour % 12 || 12
          }
        }
      }

      this.emitNewTime()
    },
    emitNewTime() {
      const newDate = new Date(this.selectedDate)
      newDate.setHours(this.hour, this.minute, this.second)
      this.emit(newDate)
    },
  },
}
</script>
