<template>
  <div
    v-if="commentType === 'comments'"
    class="flex flex-col bg-white shadow-sm border !border-[#eef1f4] rounded-md"
  >
    <div
      @click="toggleExpansion"
      class="flex justify-between items-center p-3 cursor-pointer"
    >
      <div class="flex flex-row items-center space-x-2">
        <div>
          <img
            width="20"
            height="20"
            :src="require('@assets/img/icons/planner/post_comments.svg')"
            alt="post_comment"
          />
        </div>
        <h3 class="text-base font-normal">Comments</h3>
        <div
          v-if="totalComments"
          class="
            bg-red-500
            p-0.5
            rounded-full
            w-5
            h-5
            flex
            items-center
            justify-center
            text-white
          "
          >{{ totalComments }}</div
        >
      </div>
      <i :class="`fas fa-chevron-${chevronDirection}`"></i>
    </div>
    <div v-show="isExpanded" class="flex flex-col justify-center p-3">
      <div class="py-2 border rounded-md p-2 mb-3 cst-editor">
        <AtTa
          v-model="comment.text"
          class="mentions-bottom"
          :hover-select="true"
          :members="
            comment.note
              ? getWorkspaceClientsName
              : getActiveWorkspaceMembersName
          "
        >
          <textarea
            id="comment-box-top"
            ref="commented"
            v-model="comment.text"
            :disabled="loader.comments || loader.file"
            class="rounded-md w-full p-2 border-0 text-sm min-h-[3rem]"
            maxlength="2200"
            placeholder="Add your comment here"
            rows="4"
          ></textarea>
        </AtTa>
        <div class="flex flex-row items-center space-x-2">
          <div v-if="comment.media.length" class="flex flex-wrap space-x-1.5">
            <div
              v-for="(media, key) in comment.media"
              :key="key"
              v-tooltip="media.name"
              class="flex flex-col group relative"
            >
              <img
                :src="media.link"
                alt=""
                class="w-20 h-20 rounded cursor-pointer"
              />
              <i
                class="
                  cs-cross
                  text-xxs
                  absolute
                  hidden
                  group-hover:block
                  top-0.5
                  right-0.5
                  text-center
                  w-4
                  h-4
                  bg-red-800
                  text-white
                  bg-opacity-75
                  hover:bg-opacity-90
                  cursor-pointer
                  rounded-full
                "
                @click="
                  () => {
                    comment.media.splice(key, 1)
                  }
                "
              ></i>
              <i
                class="
                  absolute
                  hidden
                  group-hover:block
                  w-full
                  bottom-0
                  cs-eye
                  text-center
                  p-1.5
                  bg-black-900
                  text-white
                  bg-opacity-75
                  hover:bg-opacity-90
                  cursor-pointer
                "
                @click="previewMediaImage(media.link)"
              ></i>
            </div>
          </div>
          <div v-if="loader.file">
            <div
              class="w-20 h-20 rounded border flex justify-center items-center"
            >
              <clip-loader
                :color="'#000000'"
                :size="'13px'"
                class="spinner"
                variant="info"
              ></clip-loader>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-between mt-3 px-2">
          <!-- Emoji attachments,Media and hashtags-->
          <div class="flex space-x-2 justify-end items-center">
            <!--toggle for private note -->
            <div
              v-if="!isClient"
              class="flex items-center space-x-1 xl:space-x-2"
            >
              <CstSwitch
                v-model="comment.note"
                size="small"
                :disabled="loader.comments || loader.file"
              ></CstSwitch>
              <label
                  v-tooltip="'Internal notes are visible only to your team. Clients will not see these notes.'"
                  for="private-note" class="text-sm text-gray-700 pt-2"
                >Internal Note</label
              >
            </div>
            <div
              v-if="!isClient"
              class="h-[1.2rem] mx-2 w-px bg-gray-600"
            ></div>
            <div class="relative">
              <div
                @click="emojiPickerStates.comment = !emojiPickerStates.comment"
              >
                <div
                  v-tooltip.top="'Add an emoji'"
                  class="
                    w-8
                    h-8
                    flex
                    items-center
                    justify-center
                    bg-gray-400
                    rounded-md
                    cursor-pointer
                  "
                >
                  <i
                    class="
                      fal
                      fa-smile-beam
                      text-blue-700 text-base
                      font-weight-500
                    "
                  >
                  </i>
                </div>
              </div>
              <CstEmojiPicker
                v-if="emojiPickerStates.comment"
                position="top-start"
                :is-open="emojiPickerStates.comment"
                @on-select="onEmojiSelect"
                @handle-click="emojiPickerStates.comment = false"
              >
              </CstEmojiPicker>
            </div>

            <label
              v-tooltip.top="{
                content: 'Add media file',
              }"
              class="
                cursor-pointer
                w-8
                h-8
                mb-0
                flex
                items-center
                justify-center
                bg-gray-400
                rounded-md
              "
              :for="`comment-file-input-${plan._id}`"
            >
              <img
                width="16"
                height="16"
                :src="require('@assets/img/icons/planner/add_image.svg')"
                alt="add_media"
              />
            </label>
            <input
              :id="`comment-file-input-${plan._id}`"
              accept="image/*"
              class="d-none"
              type="file"
              @change.prevent="uploadCommentImage(false)"
            />
            <i
              v-tooltip.top="{ content: 'Tag people' }"
              class="
                far
                fa-at
                cursor-pointer
                flex
                w-8
                h-8
                items-center
                justify-center
                bg-gray-400
                rounded-md
                text-blue-700
                font-1rem
              "
              @click="() => triggerAt('commented')"
            ></i>
          </div>

          <!-- Add Comment Button -->
          <div class="flex justify-start items-center">
            <button
              :class="{
                'cursor-not-allowed':
                  loader.comments || loader.file || comment.text === '',
              }"
              :disabled="
                loader.comments ||
                loader.file ||
                comment.text === '' ||
                processingComment
              "
              class="py-1.5 px-2 border-0 bg-primary-variant-1 rounded-md"
              @click="handleComment(false)"
            >
              <clip-loader
                v-if="processingComment"
                :color="'#dadada'"
                :size="'16px'"
                class="spinner"
                variant="info"
              ></clip-loader>
              <template v-else>
                <img
                  width="16"
                  height="16"
                  :src="require('@assets/img/icons/planner/send_message.svg')"
                  alt="send_message"
                />
              </template>
            </button>
          </div>
        </div>
      </div>
      <!-- Comments Display -->
      <template v-if="getSortedComments.length > 0" class="my-2">
        <div
          v-for="(message, index) in feedView
            ? getSortedComments.slice(0, 3)
            : getSortedComments"
          :key="`comments_v2_${index}`"
          :class="['border-l-4', 'border-[#FFD700]']"
        >
          <!-- internal Comments Display -->
          <div>
            <CommentCard
              :is-author="isAuthor(message)"
              :is-client="isClient"
              :type="getCommentType(message)"
              :message="message"
              :plan-id="plan._id"
              :is-note="message.is_note"
              :feed-view="feedView"
              @update-comment="updateComment"
              @open-image-light-box="previewMediaImage"
              @update-comment-delete-list="updateCommentDeleteList"
            ></CommentCard>
          </div>
        </div>
        <CstButton
          v-if="feedView && getSortedComments.length > 3"
          class="text-gray-700 hover:text-primary-cs"
          variant="text"
          @click="openPreviewPlanModal"
          >View all</CstButton
        >
      </template>
    </div>
    <VueEasyLightbox
      :imgs="imgs"
      :visible="visible"
      @hide="handleHide"
    ></VueEasyLightbox>
  </div>

  <!--External Comment-->
  <div
    v-else-if="commentType === 'external'"
    class="flex flex-col bg-white shadow-sm border !border-[#eef1f4] rounded-md"
  >
    <div
      @click="toggleExpansion"
      class="flex justify-between items-center p-3 cursor-pointer"
    >
      <div class="flex flex-row items-center space-x-2">
        <div>
          <img
            width="20"
            height="20"
            :src="require('@assets/img/icons/planner/post_comments.svg')"
            alt="post_comment"
          />
        </div>
        <h3 class="text-base font-normal">Comment</h3>
      </div>
      <i :class="`fas fa-chevron-${chevronDirection}`"></i>
    </div>
    <div class="flex-1 p-3" v-show="isExpanded">
      <!-- Comment Form -->
      <div class="py-2 p-2">
        <div>
          <AtTa
            v-model="comment.text"
            class="mentions-bottom"
            :hover-select="true"
          >
            <textarea
              id="comment-box-external"
              ref="commented"
              v-model="data.comment"
              :disabled="loader.comments"
              class="rounded-md w-full p-2 text-sm cst-editor min-h-[3rem]"
              maxlength="2200"
              placeholder="Add your comment here"
              rows="4"
            ></textarea>
          </AtTa>
        </div>
        <div class="flex item-center gap-2">
          <CstFloatingLabelInput
            id="full-name-preview"
            v-model="data.name"
            class="mb-2 h-12 cst-editor"
            type="text"
            label="Full Name *"
          >
          </CstFloatingLabelInput>

          <CstFloatingLabelInput
            id="email-preview"
            v-model="data.email"
            class="mb-2 h-12 cst-editor"
            type="email"
            label="Email *"
          >
          </CstFloatingLabelInput>
        </div>
        <div class="flex items-center justify-end mt-3">

          <!-- Add Comment Button -->
          <div class="flex justify-start items-center">
            <button
              :class="{
                'cursor-not-allowed':
                  data.name === '' || data.email === '' || data.comment === '',
              }"
              :disabled="
                data.name === '' || data.email === '' || data.comment === ''
              "
              class="p-1.5 border-0 bg-primary-variant-1 rounded-md"
              @click="onSubmit"
            >
              <clip-loader
                v-if="processingComment"
                :color="'#dadada'"
                :size="'16px'"
                class="spinner"
                variant="info"
              ></clip-loader>
              <template v-else>
                <img
                  width="16"
                  height="16"
                  :src="require('@assets/img/icons/planner/send_message.svg')"
                  alt="send_message"
                />
              </template>
            </button>
          </div>
        </div>
      </div>

      <!-- Comments Display -->
      <template
        v-if="getSortedComments.length > 0"
        class="my-2 border border-t-2"
      >
        <div
          v-for="(message, index) in getSortedComments"
          :key="`comments_v2_${index}`"
        >
          <!-- internal Comments Display -->
          <div>
            <CommentCard
              :external="true"
              :type="getCommentType(message)"
              :message="message"
              :plan-id="plan._id"
              :is-note="message.is_note"
              :feed-view="feedView"
              @update-comment="updateComment"
              @open-image-light-box="previewMediaImage"
              @update-comment-delete-list="updateCommentDeleteList"
            ></CommentCard>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@common/lib/event-bus'
import CstSwitch from '@ui/Switch/CstSwitch.vue'
import CommentCard from '@src/modules/planner_v2/components/CommentCard'
import {
  computed,
  defineComponent,
  inject,
  reactive,
  ref,
  watch,
  watchEffect,
} from 'vue'
import Helpers from '@src/modules/planner_v2/mixins/Helpers'
import AtTa from 'vue-at/dist/vue-at-textarea.umd'
import VueEasyLightbox from 'vue-easy-lightbox'
import CstEmojiPicker from '@ui/EmojiPicker/CstEmojiPicker'
import CstButton from '@ui/Button/CstButton.vue'
import CstFloatingLabelInput from '@ui/Input/CstFloatingLabelInput'
import {
  ACTION_TYPES,
  serviceShareLink,
} from '@src/modules/planner_v2/services'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import { addComment } from '@common/composables/useCommentsAndNotes'
import { useStore } from '@state/base'

export default defineComponent({
  name: 'CommentsAndNotes',
  components: {
    CommentCard,
    AtTa,
    VueEasyLightbox,
    CstEmojiPicker,
    CstFloatingLabelInput,
    CstSwitch,
    CstButton,
  },
  mixins: [Helpers],
  props: {
    plan: {
      type: Object,
      default: () => {},
    },
    commentType: {
      type: String,
      default: 'comments',
    },
    commentsAccordion: {
      type: Boolean,
      default: true,
    },
    token: {
      type: String,
      default: '',
    },
    feedView: {
      type: Boolean,
      default: false,
    },
    isClient: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const root = inject('root')
    const { dispatch, getters } = useStore()
    const token = computed(() => props.token)
    const moment = require('moment-timezone/builds/moment-timezone-with-data.min')
    const processingComment = ref(false)
    const visible = ref(false)
    const comment = ref({
      text: '',
      mentioned_user: [],
      media: [],
      note: false,
    })

    const loader = ref({
      file: false,
      comments: false,
    })
    const commented = ref(null)
    const noted = ref(null)
    const imgs = ref('')
    // if post has comments then show the expanded view
    const isExpanded = ref(
      props.commentsAccordion && (props.plan.comments?.length > 0 || props.plan.external_comments?.length > 0 || props.plan.external_actions?.length > 0)
    )
    const deletedComments = ref([])
    const data = reactive({
      comment: '',
      type: 'comment',
      plans: [],
      name: '',
      email: '',
      token: '',
    })
    const emojiPickerStates = reactive({
      comment: false,
      internalNote: false,
      externalComment: false,
    })

    const getCommentType = (comment) => {
      // external comment
      if (comment?.external_comment) return 'external'
      return comment?.is_note ? 'notes' : 'comments'
    }

    const toggleExpansion = () => {
      isExpanded.value = !isExpanded.value
    }

    const chevronDirection = computed(() => (isExpanded.value ? 'up' : 'down'))

    // Check if user is the author of the comment
    const isAuthor = (comment) => {
      return comment?.user_id === getters.getProfile?._id
    }

    /**
     * Fetches active client workspace name for mentioning
     * @type {ComputedRef<*[]>}
     */
    const getWorkspaceClientsName = computed(() => {
      const members = []
      if (props.commentType !== 'external') {
        getters.getActiveWorkspace.members.filter(function (member) {
          if (
            member.user &&
            (!member.membership || member.membership !== 'client')
          )
            members.push(member.user.full_name.replace(/ /g, ''))
        })
      }
      return members
    })
    /**
     * Fetches sorted comment from external comment and actions
     * @type {ComputedRef<*[]>}
     */
    const getSortedComments = computed(() => {
      let comments = []
      // Handle external comments
      if (props.plan.external_comments?.length > 0) {
        comments = props.plan.external_comments.map((item) => ({
          ...item,
          external_comment: true,
        }))
      }
      // Handle external actions
      if (props.plan.external_actions?.length > 0) {
        props.plan.external_actions.forEach((action) => {
          if (action) comments.push({ ...action, external_comment: true })
        })
      }
      // Handle internal comments
      if (props.plan.comments?.length > 0) {
        props.plan.comments.forEach((comment) => {
          // filter out notes if client or commentType is external
          if (
            comment.is_note &&
            (props.isClient || props.commentType === 'external')
          )
            return
          comments.push({ ...comment, external_comment: false })
        })
      }
      // sort by time created
      comments.sort(function (a, b) {
        const timeA = moment.parseZone(a.created_at).utc(true).format()
        const timeB = moment.parseZone(b.created_at).utc(true).format()
        return timeB.localeCompare(timeA)
      })
      return comments
    })
    /**
     * Returns list of comments exisitng in a plan.
     * @type {ComputedRef<*>}
     */
    const commentsList = computed(() => {
      return props.plan?.comments.filter(
        (item) => !deletedComments.value.includes(item._id)
      )
    })

    /**
     * Returns total number of comments in a plan
     * @type {ComputedRef<unknown>}
     */
    const totalComments = computed(() => {
      const length = 0
      // if (props.plan.type === 'Composer Article') return length
      return getSortedComments.value?.length || length
    })

    /**
     * click event for mentioning user profiles
     * @param ref
     */
    const triggerAt = (ref) => {
      let eds = null
      if (ref === 'commented') eds = commented.value
      else eds = noted.value
      eds.focus()
      document.execCommand('insertText', 0, ' @')
      const e = document.createEvent('HTMLEvents')
      e.initEvent('input', true, true)
      eds.dispatchEvent(e)
    }

    /**
     * Method for handling image upload for comments/internal notes
     * @returns {Promise<void>}
     */
    const uploadCommentImage = async () => {
      loader.value.file = true
      const res = await dispatch('uploadImageForComment', event)
      loader.value.file = false
      if (res?.data?.status) {
        comment.value.media.push(res.data.media)
      } else {
        await dispatch('toastNotification', {
          message: res.data.message,
          type: 'error',
        })
      }
    }

    /**
     * Resets the comment to default
     */
    const resetComment = () => {
      console.log('METHOD::resetComment')
      comment.value.text = ''
      comment.value.mentioned_user = []
      comment.value.media = []
      comment.value.note = false
    }

    /**
     * Handles the processing of comment addition.
     * @returns {Promise<void>}
     */
    const handleComment = async () => {
      console.log('METHOD::handleComment ', props.plan?._id)
      processingComment.value = true
      const payload = {
        comment: comment.value.text,
        is_note: comment.value.note,
        media: comment.value.media,
        mentioned_user: root.mentionedUserIdsList(comment.value.text),
        notification: '',
        plan_id: props.plan._id,
        title: null,
        type: 'list_plans',
        workspace_id: getters.getActiveWorkspace._id,
      }

      const commentResponse = await addComment(payload)
      if (
        commentResponse?._id &&
        !props.plan.comments.find((item) => item._id === commentResponse._id)
      ) {
        props.plan.comments.unshift(commentResponse)
        EventBus.$emit('increase-comment-count', { id: props.plan._id, isNote })
      }
      resetComment() // Reset comment object after adding a comment
      processingComment.value = false
    }

    /**
     * Event handler for updating comments
     * @param comment
     */
    const updateComment = (payload) => {
      const { comment, id, is_note } = payload
      const index = props.plan.comments.findIndex((item) => item._id === id)
      if (index > -1) {
        props.plan.comments[index].comment = comment
        props.plan.comments[index].is_note = is_note
      }
    }

    /**
     * Event handler for previewing comments/internal notes images
     * @param image
     */
    const previewMediaImage = (image) => {
      console.log('openImageLightBox', image)
      if (image) {
        imgs.value = image
        visible.value = true
      }
    }

    /**
     * Handler for hiding previewed image
     */
    const handleHide = () => {
      visible.value = false
    }

    const onEmojiSelect = (emojiUnicode, isNote) => {
      // Get the current textarea element
      const textarea = isNote ? noted.value : commented.value
      const cursorPosition = textarea.selectionStart

      // Insert the emoji at the cursor position
      const newText =
        textarea.value.slice(0, cursorPosition) +
        emojiUnicode +
        textarea.value.slice(cursorPosition)

      // Update the textarea value and cursor position
      if (isNote) {
        internalNote.value.text = newText
      } else {
        comment.value.text = newText
      }

      textarea.value = newText
      textarea.selectionStart = cursorPosition + emojiUnicode.length
      textarea.selectionEnd = cursorPosition + emojiUnicode.length
    }

    const onSubmit = async () => {
      // validate
      if (!data.name.trim()) {
        dispatch('toastNotification', {
          message: 'Please enter your name',
          type: 'error',
        })
        return
      }

      if (!data.email.trim()) {
        dispatch('toastNotification', {
          message: 'Please enter your valid email',
          type: 'error',
        })
        return
      }

      if (data.type === 'comment' && !data.comment.trim()) {
        dispatch('toastNotification', {
          message: 'Please enter your comment',
          type: 'error',
        })
        return
      }

      if (data.type === 'comment') {
        await addExternalComment()
      } else {
        await externalAction()
      }
    }

    const externalAction = async () => {
      try {
        await serviceShareLink(ACTION_TYPES.APPROVE_REJECT_PLAN, {
          token: token.value,
          plans: [props.plan?._id],
          action: {
            name: data.name,
            email: data.email,
            comment: data.comment,
            type: data.type,
          },
        })
        dispatch('toastNotification', {
          message: 'Post state updated successfully',
          type: 'success',
        })
      } catch (error) {
        dispatch('toastNotification', {
          message: error.message || UNKNOWN_ERROR,
          type: 'error',
        })
      }
    }

    const addExternalComment = async () => {
      try {
        await serviceShareLink(ACTION_TYPES.ADD_COMMENT, {
          token: token.value,
          plans: [props.plan?._id],
          comment: {
            name: data.name,
            email: data.email,
            comment: data.comment,
          },
        })
        // Show the comment in the UI
        if (!props.plan?.external_comments) {
          props.plan.external_comments = []
        }
        props.plan.external_comments.unshift({
          name: data.name,
          email: data.email,
          comment: data.comment,
          created_at: new Date().toISOString(),
        })
        comment.value.text = data.name = data.email = data.comment = ''
        dispatch('toastNotification', {
          message: 'Comment added successfully',
          type: 'success',
        })
      } catch (error) {
        dispatch('toastNotification', {
          message: error.message || UNKNOWN_ERROR,
          type: 'error',
        })
      }
    }

    const updateCommentDeleteList = (id) => {
      props.plan.comments = props.plan.comments.filter(
        (item) => item._id !== id
      )
      deletedComments.value.push(id)
      EventBus.$emit('decrease-comment-count', {
        id: props.plan._id,
        isNote: isNote(),
      })
    }
    const isNote = () => {
      return props.commentType === 'notes'
    }
    const openPreviewPlanModal = () => {
      EventBus.$emit('preview-plan', props.plan?._id)
    }

    const getExternalEmailAndName = () => {
      data.email = localStorage.getItem('external_email') || ''
      data.name = localStorage.getItem('external_name') || ''
    }

    return {
      processingComment,
      visible,
      comment,
      loader,
      commented,
      noted,
      getWorkspaceClientsName,
      commentsList,
      imgs,
      totalComments,
      emojiPickerStates,
      moment,
      getSortedComments,
      data,
      triggerAt,
      uploadCommentImage,
      resetComment,
      handleComment,
      previewMediaImage,
      handleHide,
      onEmojiSelect,
      onSubmit,
      deletedComments,
      updateCommentDeleteList,
      updateComment,
      getCommentType,
      isNote,
      openPreviewPlanModal,
      chevronDirection,
      toggleExpansion,
      isExpanded,
      isAuthor,
      getExternalEmailAndName
    }
  },

  mounted() {
    this.getExternalEmailAndName()
  },
})
</script>

<style lang="scss">
.emoji_dropdown .dropdown_header i {
  font-size: 1rem !important;
}
</style>
